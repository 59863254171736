<template>
  <v-app :style="colorVapp">
    <div>
      <onebox_toolbar
        :quicksearch="true"
        :parentfolder="parentfolder"
        @inputdata="filteredItems"
        @loadfile="fn_loadfileandfolder_child()"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
      ></onebox_toolbar>
      <v-content>
        <v-card class="elevation-0">
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <v-overlay :value="processdelete" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <!-- <v-overlay :value="processalldelete">
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress"
              >&nbsp; &nbsp; กำลังเลือกข้อมูลเพื่อลบไฟล์</span
            >
          </v-overlay> -->
          <!-- <v-toolbar dense class="elevation-0">
          <v-avatar color="grey darken-3" size="35" class="mr-6">
            <v-icon dark size="27">delete_sweep</v-icon>
          </v-avatar>
          <v-toolbar-title>
            <span
              style="color: #424242; font-size: 18px; font-weight: 600;"
            >&nbsp;{{ $t('toolbar.mytrash')}}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>-->
          <!-- <v-divider></v-divider> -->
          <!-- Header Page lg -->
          <v-list nav dense :color="color.BG" v-if="resolutionScreen >= 500">
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center pt-1">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">delete</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="ml-n2 pt-2 pb-1">
                  <!-- <span
                  v-if="$route.query.type === 2"
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Businessname')}}</span>
                <span
                  v-else
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Personalname')}}</span>-->
                  <v-btn rounded text disabled>
                    <span :style="headerPage">{{ $t("toolbar.mytrash") }}</span>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action
                class="pa-0 text-center"
                v-if="resolutionScreen < 500 ? false : true"
              >
                <v-layout>
                  <v-chip
                    class="ma-2"
                    :color="color.theme"
                    :text-color="color.chipText"
                  >
                    <v-icon left>folder</v-icon>
                    {{ count_folder }}
                    {{ count_folder > 1 ? $t("folders") : $t("folder") }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    :color="color.theme"
                    :text-color="color.chipText"
                  >
                    <v-icon left>mdi-file</v-icon>
                    {{ count_file }}
                    {{ count_file > 1 ? $t("files") : $t("file") }}
                  </v-chip>
                </v-layout>
                <!-- Sort File ใหม่-->
                <v-layout 
                    row 
                    wrap 
                    class="pl-3 pr-0" 
                    v-if="resolutionScreen >= 500"
                    >
                    <v-list nav :color="color.BG" class="py-0">
                          <v-list-item>
                            <v-layout>
                              <v-flex class="text-right" lg12>
                                <v-menu offset-y rounded="lg">
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      small
                                      rounded
                                      class="white--text"
                                      :color="color.theme"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>mdi-sort</v-icon>
                                      {{ $t("sorting.sort") }}:
                                      <div class="font-weight-bold">
                                        {{
                                          $t(
                                            sorting["sort"].find(
                                              (x) => x.id === sort_by
                                            ).text
                                          )
                                        }},
                                        {{
                                          $t(
                                            sorting["direction"].find(
                                              (x) => x.id === direction
                                            ).text
                                          )
                                        }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list dense nav>
                                    <v-list-item-group
                                      v-model="sort_by"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['sort']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                    <v-divider class="mt-2" />
                                    <v-list-item-group
                                      class="mt-4"
                                      v-model="direction"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting[
                                          'direction'
                                        ]"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-list-item>
                        </v-list>
                  </v-layout>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <!-- Header Page xs -->
          <v-list nav :color="color.BG" class="mb-n2" v-else>
            <v-list-item class="text-left">
              <v-chip
                :style="headerChipPage"
                :color="$vuetify.theme.dark ? '#989898' : 'white'"
              >
                <v-avatar class="text-center">
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    dark
                    size="20"
                    >mdi-trash-can</v-icon
                  >
                </v-avatar>
                <v-list-item-title class="ml-2">
                  <span :style="headerPageMobile">{{
                    $t("toolbar.mytrash")
                  }}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
          </v-list>

          <!-- <v-list nav dense>
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar color="grey darken-3" size="35">
                <v-icon dark size="27">delete_sweep</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  style="color: #424242; font-size: 18px; font-weight: 600;"
                >&nbsp;{{ $t('toolbar.mytrash')}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>-->

          <!-- <div id="demo"> -->
          <v-card
            class="elevation-0"
            :color="color.BG"
            id="thiscontainer_trash"
          >
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-layout row wrap justify-center class="mt-n2">
                  <!-- <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex> -->
                  <v-flex lg12 class="pa-4">
                    <v-layout
                      row
                      wrap
                      class="pl-3 pr-3"
                      v-if="resolutionScreen >= 500"
                    >
                      <!-- <v-flex lg2 xs4>
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                        :color="color.theme"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                       <br />
                    </v-flex> -->
                      <v-flex lg2 xs4>
                        <!-- ส่วนของตัว select size -->
                        <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                      </v-flex>
                      <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                      <!-- <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down">
                      <v-alert
                        dense
                        height="41"
                        v-model="clickSort"
                        :color="color.alertSort"
                        dark
                        v-show="clickSort"
                        style="margin-bottom: 0%;"
                      >
                        <div>
                          <v-icon :color="color.alertText" v-if="multiSort === true">mdi-chevron-up</v-icon>
                          <v-icon :color="color.alertText" v-else-if="multiSort === false">mdi-chevron-down</v-icon>
                          <span :style="colorSort" v-if="sortby === 'name'">{{ $t("Filter.name") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'date'">{{ $t("Filter.date") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'size'">{{ $t("Filter.size") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'position'">{{ $t("Filter.position") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'owner'">{{ $t("Filter.owner") }}</span>
                          <v-btn
                            width="24"
                            height="24"
                            style="float: right;"
                            text
                            fab
                            x-small
                            :color="color.alertSort"
                            dark
                            @click="checkSort"
                          >
                            <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                          </v-btn>
                        </div>
                      </v-alert>
                    </v-flex> -->
                    </v-layout>
                    <v-layout
                      row
                      wrap
                      class="pl-3 pr-1"
                      v-if="resolutionScreen >= 500"
                    >
                      <!-- Sort File -->
                      <v-flex lg8 xs8>
                        <!-- ส่วนของตัว select size -->
                        <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                      </v-flex>
                      <!-- <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                        <v-list nav :color="color.BG" class="py-0">
                          <v-list-item>
                            <v-layout>
                              <v-flex class="text-right" lg12>
                                <v-menu offset-y rounded="lg">
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      small
                                      rounded
                                      class="white--text"
                                      :color="color.theme"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>mdi-sort</v-icon>
                                      {{ $t("sorting.sort") }}:
                                      <div class="font-weight-bold">
                                        {{
                                          $t(
                                            sorting["sort"].find(
                                              (x) => x.id === sort_by
                                            ).text
                                          )
                                        }},
                                        {{
                                          $t(
                                            sorting["direction"].find(
                                              (x) => x.id === direction
                                            ).text
                                          )
                                        }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list dense nav>
                                    <v-list-item-group
                                      v-model="sort_by"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['sort']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                    <v-divider class="mt-2" />
                                    <v-list-item-group
                                      class="mt-4"
                                      v-model="direction"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting[
                                          'direction'
                                        ]"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-list-item>
                        </v-list>
                      </v-flex> -->
                    </v-layout>
                    <v-layout v-if="resolutionScreen >= 500">
                      <v-flex xs6 lg4>
                        <div
                          v-if="statusmutipledelete === false"
                          class="pt-2 hidden-sm-and-down"
                        >
                          <v-btn
                            @mouseover="show_menu = true"
                            @mouseleave="show_menu = false"
                            :color="color.theme"
                            :dark="color.darkTheme"
                            rounded
                            class="ma-1"
                            @click="
                              (statusmutipledelete = true), (show_menu = false), (arrayfileidselect = [])
                            "
                            fab
                            small
                          >
                            <v-icon>delete</v-icon>&nbsp;
                            <!-- {{ $t('myinboxPage.multipledownload')}} -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_menu"
                              :content="$t('balloon.menu_trash')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_menu"
                              :content="$t('balloon.menu_trash')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                        <div v-else class="pt-2">
                          <v-btn
                            @mouseover="show_delete = true"
                            @mouseleave="show_delete = false"
                            fab
                            small
                            color="error"
                            class="ma-1"
                            @click="opendialogtrue()"
                          >
                            <v-icon>delete_forever</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_delete"
                              :content="$t('balloon.permanently_delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_delete"
                              :content="$t('balloon.permanently_delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_restore = true"
                            @mouseleave="show_restore = false"
                            fab
                            small
                            color="green"
                            dark
                            class="ma-1"
                            @click="fn_opendialogmultirestore()"
                          >
                            <v-icon>restore</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-25px;"
                              :value="show_restore"
                              :content="$t('balloon.restore')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_restore"
                              :content="$t('balloon.restore')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            :color="color.theme"
                            :dark="color.darkTheme"
                            class="ma-1 elevation-3"
                            @click="
                              (statusmutipledelete = false),
                                clearmultipledelete(),
                                (show_clear = false)
                            "
                          >
                            <v-icon>clear</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs6 lg8 class="text-right">
                        <!-- ปุ่มล้างถังขยะ -->
                        <v-btn
                          @mouseover="show_alldelete = true"
                          @mouseleave="show_alldelete = false"
                          small
                          color="error"
                          class="ma-1 mt-6"
                          @click="checkClearFileandFolder()"
                          :disabled="loaddataprogress"
                        >
                          <span>{{$t('balloon.cleartrash')}}</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <!-- หน้าจอ xs -->
                    <v-layout row wrap class="mt-n2" v-else>
                      <!-- <v-flex xs5 sm3>
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex> -->
                      <!-- Sort By -->
                      <v-flex xs3 class="text-left">
                        <v-menu offset-y rounded="lg">
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              x-small
                              rounded
                              :color="color.theme"
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-1"
                            >
                              <span>{{ $t("sorting.sort") }} : </span>
                              <span
                                class="font-weight-bold"
                                :color="color.theme"
                              >
                                {{
                                  $t(
                                    sorting["sort"].find(
                                      (x) => x.id === sort_by
                                    ).text
                                  )
                                }}</span
                              ><v-icon size="20">{{
                                $t(
                                  sorting["direction"].find(
                                    (x) => x.id === direction
                                  ).text
                                ) === $t("sorting.ascending")
                                  ? "mdi-arrow-up"
                                  : "mdi-arrow-down"
                              }}</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="sort_by"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template v-for="(item, i) in sorting['sort']">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        :style="
                                          sort_by === item.id
                                            ? listOnBold
                                            : listNoBold
                                        "
                                        ><v-icon size="17" class="mr-2">{{
                                          item.icon
                                        }}</v-icon
                                        >{{ $t(item.text) }}</v-list-item-title
                                      >
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                            <v-divider class="mt-2" />
                            <v-list-item-group
                              class="mt-4"
                              v-model="direction"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template
                                v-for="(item, i) in sorting['direction']"
                              >
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        :style="
                                          direction === item.id
                                            ? listOnBold
                                            : listNoBold
                                        "
                                        >{{ $t(item.text) }}</v-list-item-title
                                      >
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <!-- Total File&Folder -->
                      <v-flex xs9 class="ml-n3 text-right">
                        <v-chip
                          style="height: 32px; border-radius: 32px;"
                          class="px-4 mr-2"
                          color="#1876D1"
                          :text-color="color.chipText"
                        >
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <!-- {{ count_folder }} {{ count_folder > 1 ? $t("folders") : $t("folder") }} -->
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ count_folder }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_folder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip
                          style="height: 32px; border-radius: 32px;"
                          class="px-4"
                          color="#5AB685"
                          :text-color="color.chipText"
                        >
                          <v-icon center size="18" class="mr-2"
                            >mdi-file</v-icon
                          >
                          <!-- {{ count_file }} {{ count_file > 1 ? $t("files") : $t("file") }} -->
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ count_file }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_file > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <br />
                    <v-card
                      v-if="resolutionScreen < 500"
                      class="mt-n2"
                      :style="displayMobile"
                    >
                      <v-list
                        two-line
                        :style="styleDisplayMobile"
                        class="elevation-0 px-2"
                        v-if="rootfile.length !== 0"
                      >
                        <v-list-item
                          :style="displayMobileForListItem"
                          v-for="item in rootfile"
                          :key="item.title"
                          style="cursor: pointer;"
                        >
                          <!-- @dblclick="gotodirectory(item.file_id,item.file_type)" -->
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              icon="link"
                              overlap
                              v-if="
                                item.file_status_sharelink === 'Y' ||
                                  item.file_status_sharelink === 'P'
                              "
                            >
                              <v-icon
                                x-large
                                :color="item.file_icon[1]"
                                style="font-weight: 100"
                                >{{ item.file_icon[0] }}</v-icon
                              >
                            </v-badge>
                            <div v-else>
                              <v-icon
                                x-large
                                :color="item.file_icon[1]"
                                style="font-weight: 100"
                                >{{ item.file_icon[0] }}</v-icon
                              >
                            </div>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title
                              style="font-size: 14px;"
                              v-text="item.file_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px;">{{
                              item.file_type === "folder"
                                ? "-"
                                : calculatesize(item.file_size)
                            }}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              icon
                              fab
                              small
                              @click="rightclickfileandfolder($event, item)"
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- 
                      <v-divider inset></v-divider>-->
                      </v-list>
                      <v-card-text
                        class="mt-4 mb-4"
                        v-if="
                          rootfile.length === 0 &&
                            disablescrolling === true &&
                            loaddataprogress === false
                        "
                      >
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="
                            'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                              color.theme +
                              ';'
                          "
                          class="text-center mt-8"
                        >
                          {{ $t("tablefile.empty") }}
                        </v-list-item-title>
                      </v-card-text>
                      <!-- <v-list v-else class="elevation-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list> -->
                    </v-card>
                    <div v-else>
                      <v-data-table
                        v-model="filemultipledelete"
                        :headers="headers"
                        :items="rootfile"
                        :no-data-text="$t('tablefile.empty')"
                        :single-select="singleSelect"
                        :search="search"
                        item-key="file_id"
                        item-selected
                        :show-select="statusmutipledelete"
                        class="elevation-0"
                        :items-per-page="rootfile.length"
                        :hide-default-footer="true"
                        :loading="loaddataprogress"
                        :loading-text="$t('tablefile.loadingitems')"
                      >
                        <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                        </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                          :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_owner_th`]="{ header }">
                          <span
                          :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template
                          v-slot:[`header.last_update_name_th`]="{ header }"
                        >
                          <span
                          :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.trash_dtm`]="{ header }">
                          <span
                          :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                          :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <!-- <template v-slot:[`header.file_position`]="{ header }">
                        <span
                          class="pointer"
                          @click="(multiSort = !multiSort), (sortby = 'position'), (clickSort = true), sortFile(rootfile)"
                          :style="headerTable"
                          >{{ $t(header.text) }}</span
                        >
                        </template> -->
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <!-- @dblclick="gotodirectory(props.item.file_id,props.item.file_type)" -->
                          <tr
                            @contextmenu="rightclickfileandfolder($event, props.item)"
                            :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                            @click="setSelectitemtable(props.item)"
                          >
                            <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                          </td>-->
                            <td
                              class="text-center"
                              v-show="statusmutipledelete"
                            >
                              <v-checkbox
                                v-model="filemultipledelete"
                                :value="props.item"
                              />
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                            </td>
                            <td width="50%" :title="props.item.file_name">
                              <div
                                style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:530px"
                              >
                                {{ props.item.file_name }}
                              </div>
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="$t('default') === 'th'"
                            >
                              {{ props.item.file_owner_th }}
                            </td>
                            <td width="10%" class="text-center" v-else>
                              {{ props.item.file_owner_eng }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="$t('default') === 'th'"
                            >
                              {{ props.item.last_update_name_th || "-" }}
                            </td>
                            <td width="10%" class="text-center" v-else>
                              {{ props.item.last_update_name_en || "-" }}
                            </td>
                            <td width="15%" class="text-center">
                              {{ formatdatetime(props.item.trash_dtm) }}
                            </td>
                            <td width="10%" class="text-center">
                              {{ calculatesize(props.item.file_size) }}
                            </td>
                            <!-- <td width="10%" style="max-width: 250px">{{ props.item.file_position }}</td> -->
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                            ></td>
                            <td width="10%" v-else class="text-center">
                              <v-btn
                                class="elevation-0"
                                fab
                                :disabled="loadingrestore"
                                small
                                @click="rightclickfileandfolder($event, props.item)"
                                outlined
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <!-- <v-layout row wrap justify-center>
                    <v-flex xs11 lg11>
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :color="color.theme"
                        v-if="rootfile.length > 0"
                        @input="changepage()"
                      ></v-pagination>
                    </v-flex>
                  </v-layout> -->

                    <!-- <infinite-loading @infinite="loadfile"></infinite-loading> -->
                  </v-flex>
                </v-layout>
                <scroll-loader
                  class="pa-0"
                  :loader-method="fn_loadfileandfolder"
                  :loader-disable="disablescrolling"
                >
                </scroll-loader>
                <div class="text-center" v-if="loaddataprogress === true">
                  <v-progress-circular
                    :size="50"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-container>

              <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
              <trashrightclick
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                @callremove="msgalert"
                @callupdatestatusfile="setPermissionFile"
                @calldetail="opendialogdetail = true"
                :file="currentfile"
                @closecurrent="
                  (showcurrentMenu = false),
                    fn_loadfileandfolder_child(),
                    loadstorage()
                "
                @closenoreload="showcurrentMenu = false"
              ></trashrightclick>
              <!-- Add new -->
              <dl_movefilefromtrash
                :show="opendlmovefile"
                :filedata="file"
                :parentfoldername="parentfoldername"
                :parentfolder="parentfolder"
                @updatedatafile="closeDialogMoveTrash()"
                @updatemultidatafile="closeMultiFileandFolder"
                @closedlmovefile="(opendlmovefile = false), $emit('openDrag')"
                @opendmovefile="opendlmovefile = true"
                @closeDrag="$emit('closeDrag')"
                @openDrag="$emit('openDrag')"
                ></dl_movefilefromtrash>
              
              <!-- @closecurrent="showcurrentMenu = false,loadfileandfolder(), loadstorage()" -->
              <trashforever
                :show="opendialog"
                @closedialog="
                  (opendialog = false),
                    (statusmutipledelete = false),
                    clearmultipledelete()
                "
                @multidelete="multipledelete()"
                :file="filemultipledelete"
                :processdelete="processdelete"
              ></trashforever>
              <!-- Old V -->
              <!-- <dialogmultirestore
                :show="opendialogmultirestore"
                @closedialog="opendialogmultirestore = false"
                @closedialogreload="
                  (opendialogmultirestore = false),
                    (filemultipledelete = []),
                    fn_loadfileandfolder_child(),
                    loadstorage()
                "
                :file="filemultipledelete"
              ></dialogmultirestore> -->

              <!-- New V -->
              <dialogmultirestore
                :show="opendialogmultirestore"
                @closedialog="(opendialogmultirestore = false), (loadingrestore = false)"
                @closedialogreload="
                  (opendialogmultirestore = false),
                    (filemultipledelete = []),
                    (statusmutipledelete = false),
                    (loadingrestore = false),
                    fn_loadfileandfolder_child(),
                    loadstorage()
                "
                @closedialogwithoutprogress="
                    (opendialogmultirestore = false),
                    (filemultipledelete = []),
                    (statusmutipledelete = false)"
                @opendialogmovefileandfolder="openMoveFileAndFolder"
                @clearpathmoveto="testMoveto = ''"
                :file="filemultipledelete"
                :filefoldersuccess="testsuccessfile"
                :filefolderchild="child_item"
                :filefoldermoveto="testMoveto"
              ></dialogmultirestore>
              <dialogmultideleteforever
                :show="opendialogmultideleteforever"
                :filedata="filemultipledelete"
                @closedialog="
                  (opendialogmultideleteforever = false),
                    (statusmutipledelete = false),
                    (filemultipledelete = []),
                    clearmultipledelete()
                "
                @closedialogreload="
                  (opendialogmultideleteforever = false),
                    (filemultipledelete = []),
                    fn_loadfileandfolder_child(),
                    loadstorage()
                "
              ></dialogmultideleteforever>
              <dialogsnackbarcleartrash
                :show="opendialogsnackbarcleartrash"
                :filedata="filemultipledelete"
                :showdatadelete="showdatadelete"
                :statuscleartrash="statuscleartrash"
                :countdelete="countdelete"
                :countcancel="countcancel"
                @closedialog="
                  (opendialogsnackbarcleartrash = false),
                    fn_loadfileandfolder_child(),
                    loadstorage(),
                    (filemultipledelete = [])
                "
                @cancelbackupanddelete="
                  showdatadelete[countdelete - 1].canceltoken.cancel(),
                    (countcancel = 1)
                "
                @cancelselectall="sourceselectall.cancel(), (countcancel = 1)"
                :countdeleteerror="countdeleteerror"
                @detaildelete="
                  (opendialogsnackbarcleartrash = false),
                    (opendialogdetaildelete = true)
                "
              ></dialogsnackbarcleartrash>
              <keydetail
                :show="opendialogdetail"
                :filedata="filedata"
                :parentfolder="parentfolder"
                @closedkeydetail="opendialogdetail = false"
              ></keydetail>
              <restore
                :show="opendialogrestore"
                @closerestore="
                  fn_loadfileandfolder_child(),
                    loadstorage(),
                    (opendialogrestore = false)
                "
              ></restore>
              <!-- Old V -->
              <!-- <confirmdeletetrash
                :show="msgConfirm"
                :success="opendeletesuccess"
                @closedelete="opendeletesuccess = false"
                @closenoreload="
                  (opendeletesuccess = false), (msgConfirm = false)
                "
                @startremove="callremove(datadelete), (msgConfirm = false)"
              ></confirmdeletetrash> -->

              <!-- New V -->
              <confirmdeletetrash
                :show="msgConfirm"
                :success="opendeletesuccess"
                @closedelete="fn_loadfileandfolder_child(),
                    loadstorage(),(opendeletesuccess = false)"
                @closenoreload="
                  (opendeletesuccess = false), (msgConfirm = false)
                "
                @startremove="callremove(datadelete), (msgConfirm = false)"
              ></confirmdeletetrash>
              <!-- Old V -->
              <!-- <confirmcleartrash
                :show="openconfirmcleartrash"
                :statuscleartrash="statuscleartrash"
                @closedialog="openconfirmcleartrash = false"
                @cleartrash="
                  selectAllDataForClearTrash(), (openconfirmcleartrash = false)
                "
                @multidelete="
                  backupfileandfolder(),
                    (openconfirmcleartrash = false),
                    (opendialogsnackbarcleartrash = true)
                "
              ></confirmcleartrash> -->

              <!-- New V -->
              <confirmcleartrash
                :show="openconfirmcleartrash"
                :statuscleartrash="statuscleartrash"
                @closedialog="openconfirmcleartrash = false"
                @cleartrash="(filemultipledelete = rootfile),(type_delete = 'CT'),
                  opendialogdeleteforever(), (openconfirmcleartrash = false)
                "
                @multidelete="
                  opendialogdeleteforever(), (type_delete = 'MT'),
                    (openconfirmcleartrash = false)
                "
              ></confirmcleartrash>

              <!-- Old V -->
              <!-- <dialogdetaildelete
                :show="opendialogdetaildelete"
                :showdatadelete="showdatadelete"
                @closedialog="
                  (opendialogsnackbarcleartrash = true),
                    (opendialogdetaildelete = false)
                "
              ></dialogdetaildelete> -->

              <!-- New V -->
              <dialogdetaildelete
                :show="opendialogdetaildelete"
                :type="type_delete"
                :recent_offset="recent_offset"
                :showdatadelete="filemultipledelete"
                @closedialog="
                    (opendialogdetaildelete = false)
                "
                @closedialogreload="(opendialogdetaildelete = false), 
                  (statusmutipledelete = false),
                  (filemultipledelete = []),
                  (loadingrestore = false),
                  fn_loadfileandfolder_child(),
                  loadstorage()"
                ></dialogdetaildelete>
            </v-card-text>
          </v-card>
        </v-card>
      </v-content>
      <!-- <v-dialog v-model="msgConfirm" v-if="resolutionScreen >= 500" persistent max-width="500">
      <v-card>
        <v-card-title>
          <b>{{ $t("trashPage.messageboxheader") }}</b
          ><v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-icon color="red">folder</v-icon>
          {{ $t("trashPage.messageboxsubheader") }}
        </v-card-text>
        <br />
        <v-card-text>
          <v-icon color="amber">error_outline</v-icon>
          <b>{{ $t("trashPage.messageboxsubheader2") }}</b>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="color.theme" @click="msgConfirm = false" outlined :disabled="processdelete">{{
            $t("trashPage.canceldelete")
          }}</v-btn>
          <v-btn color="red  darken-1 white--text" depressed @click="callremove(datadelete)" :loading="processdelete">{{
            $t("trashPage.forcedelete")
          }}</v-btn>
        </v-card-actions>
        <v-progress-linear :active="processdelete"></v-progress-linear>
      </v-card>
    </v-dialog> -->
      <!-- <div style="visibility:hidden;" id="dropzone">
      <div id="textnode">
        <v-icon size="200" dark>cloud_upload</v-icon>
        <h3>วางไฟล์เพื่อทำการอัปโหลด</h3>
      </div>
    </div> -->
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
// import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import axios, { CancelToken } from "axios";
// Import Component
const dl_movefilefromtrash = () => import("../components/optional/dialog-movefilefromtrash");
const keydetail = () => import("../components/optional/dialog-keydetail");
const restore = () => import("../components/optional/dialog-restore");
const confirmdeletetrash = () =>
  import("../components/optional/dialog-confirmdeletetrash");
const confirmcleartrash = () =>
  import("../components/optional/dialog-confirmcleartrash");
const trashforever = () => import("../components/optional/dialog-trashforever");
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
//const currentfilerightclick = () =>
//  import("../components/contextmenu/currentfilerightclick");
const trashrightclick = () =>
  import("../components/contextmenu/trashrightclick");
const dialogmultirestore = () =>
  import("../components/optional/dialog-multirestore");
const dialogmultideleteforever = () =>
  import("../components/optional/dialog-multidelete-new");
const dialogsnackbarcleartrash = () =>
  import("../components/optional/dialog-snackbarcleartrash");
const dialogdetaildelete = () =>
  import("../components/optional/dialog-detaildelete");
// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
import onebox_toolbar from "../components/layout/layout-toolbar-new";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    confirmdeletetrash,
    restore,
    keydetail,
    onebox_toolbar,
    VueFullScreenFileDrop,
    trashrightclick,
    uploadfiles,
    uploadfolder,
    trashforever,
    dialogmultirestore,
    dialogmultideleteforever,
    confirmcleartrash,
    dialogsnackbarcleartrash,
    dialogdetaildelete,
    dl_movefilefromtrash,
  },
  data: function() {
    return {
      type_delete: "MT", // MT = Multi delete trash , CT = Clear delete Trash
      recent_offset: {limit: 20, offset: 0, all_file: 0, all_folder: 0, sort_by: "", direction: ""},
      opendialogsnackbarcleartrash: false,
      openconfirmcleartrash: false,
      opendialogdetaildelete: false,
      opendeletesuccess: false,
      opendialogrestore: false,
      filedata: {},
      opendialogdetail: false,
      show_menu: false,
      show_alldelete: false,
      show_delete: false,
      show_restore: false,
      show_clear: false,
      opendialogmultideleteforever: false,
      opendialogmultirestore: false,
      count_file: 0,
      count_folder: 0,
      disablescrolling: true,
      fileCount: 0,
      opendialog: false,
      filemultipledelete: [],
      countfolder: "",
      countfile: "",
      file: [],
      folder: [],
      search: "",
      statusmutipledelete: false,
      page: 1,
      offset: 0,
      count: 0,
      size: 20,
      listsize: [5, 20, 50, 100],
      processdelete: false,
      datadelete: {},
      msgConfirm: false,
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8],
      },
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "35%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "center",
          value: "file_owner_th",
          width: "10%",
          sortable: false,
        },
        {
          text: "tablefile.lastupdatename",
          align: "center",
          value: "last_update_name_th",
          width: "10%",
          sortable: false,
        },
        {
          text: "tablefile.trashdtm",
          value: "trash_dtm",
          width: "12%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "tablefile.fileposition",
        //   value: "file_position",
        //   width: "10%",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "10%",
          align: "center",
          sortable: false,
        },
      ],
      sortby: "",
      clickSort: false,
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      sorting: {
        sort: [
          {
            id: "name",
            text: "sorting.name",
            icon: "mdi-file-document",
          },
          {
            id: "cre_dtm",
            text: "sorting.date_created",
            icon: "mdi-calendar-multiselect",
          },
          // {
          //   id: "last_dtm",
          //   text: "sorting.date_modified",
          //   icon: "mdi-timer",
          // },
          {
            id: "trash_dtm",
            text: "sorting.date_delete",
            icon: "mdi-delete-outline",
          },
          {
            id: "type",
            text: "sorting.type",
            icon: "mdi-text",
          },
          {
            id: "size_file",
            text: "sorting.size_file",
            icon: "mdi-clipboard",
          },
        ],
        direction: [
          {
            id: "ASC",
            text: "sorting.ascending",
          },
          {
            id: "DESC",
            text: "sorting.descending",
          },
        ],
      },
      sort_by: localStorage.getItem('sort_by') !== null ? localStorage.getItem('sort_by') : "trash_dtm",
      direction: localStorage.getItem('direction_by') !== null ? localStorage.getItem('direction_by') : "DESC",
      countdelete: 0,
      statuscleartrash: "start",
      sourceselectall: "",
      sourcemultidelete: "",
      countcancel: 0,
      showdatadelete: [],
      percendelete: 0,
      timeout: -1,
      countdeleteerror: 0,
      //New variables
      parentfoldername: "",
      opendlmovefile: false,
      loadingrestore: false,
      testsuccessfile: [],
      child_item: [],
      testMoveto: [],
      arrayfileidselect: [],
    };
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.rootfile.length;
        // let l = this.fileCount;
        let s = this.size;
        console.log(Math.ceil(l / s));
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    // countfolders() {
    //   let countfolder = this.folder.length;
    //   return countfolder;
    // },
    // countfiles() {
    //   let countfile = this.file.length;
    //   return countfile;
    // },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + "!important;";
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 700; line-height: 24px;"
      );
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;";
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;";
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);";
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;";
      } else {
        return "background: #FFFFFF;";
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;";
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;";
      }
    },
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    title() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    deletesuccess() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
  },
  watch: {
    sort_by() {
      localStorage.setItem('sort_by', this.sort_by)
      this.fn_loadfileandfolder_child();
    },
    direction() {
      localStorage.setItem('direction_by', this.direction)
      this.fn_loadfileandfolder_child();
    },
  },
  methods: {
    closeDialogMoveTrash() { //Add 
      this.opendlmovefile = false
      this.loadstorage()
      this.fn_loadfileandfolder_child()
      this.$emit('openDrag')

    },

    closeMultiFileandFolder(file_move_to) { //Add 
      this.opendlmovefile = false
      this.testMoveto = file_move_to
    },
    
    opendialogdeleteforever () { // Add
      this.opendialogdetaildelete = true
    },
    fn_permission_folder (permission_text) { //Add 
      if(permission_text !== undefined){
        let permission_number = permission_text.split('')[1]
        if(permission_number < 5){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
      
    },
    reloadSortByAndDirection() {
      this.loadstorage();
      this.fn_loadfileandfolder_child();
    },
    opendialogtrue() {
      if (this.filemultipledelete.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // this.opendialog = true;
        // this.opendialogmultideleteforever = true;
        this.countcancel = 0;
        this.statuscleartrash = "multi";
        this.openconfirmcleartrash = true;
        // console.log(this.filemultipledelete);
      }
    },
    fn_opendialogmultirestore() {
      if (this.filemultipledelete.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        this.parentfolder = this.account_active["directory_id"] //Add
        this.opendialogmultirestore = true;
        console.log(this.filemultipledelete);
      }
    },
    async multipledelete() {
      this.processdelete = true;
      let filedata = [];
      for (let i = 0; i < this.filemultipledelete.length; i++) {
        if (this.filemultipledelete[i].file_type === "folder") {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "folder",
          });
        } else {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "file",
          });
        }
      }
      let payload = {
        data_id: filedata,
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
      };
      console.log("payloads", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DELETE_MULTIPLES +
            "/api/v2/multiple_delete_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.processdelete = false;
            this.loadstorage();
            this.fn_loadfileandfolder_child();
            // this.loadfileandfolder()
            this.clearmultipledelete();
            (this.opendialog = false), (this.statusmutipledelete = false);
            Toast.fire({
              icon: "success",
              title: this.$t("sharefile.deletesuc"),
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.status,
            });
            (this.opendialog = false), (this.statusmutipledelete = false);
          }
        });
    },
    clearmultipledelete() {
      this.filemultipledelete.splice(0, this.filemultipledelete.length);
    },
    // เด้ง dialog สำหรับกดยืนยันการลบทั้งหมด
    checkClearFileandFolder() {
      if (this.rootfile.length <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("trashPage.nofileandfolderintrash"),
        });
      } else {
        this.statuscleartrash = "start";
        this.openconfirmcleartrash = true;
        this.countcancel = 0;
      }
    },
    // วนยิง api ให้ได้ไฟล์ทั้งหมดในถังขยะ เพื่อเอาไปลบ
    async selectAllDataForClearTrash() { // ไม่ได้ใช้งาน
      this.countdeleteerror = 0;
      this.statuscleartrash = "start";
      this.filemultipledelete = [];
      if (this.rootfile.length > 0) {
        this.opendialogsnackbarcleartrash = true;
        let CancelToken = this.axios.CancelToken;
        this.sourceselectall = CancelToken.source();
        let cal_limit = 50;
        let cal_offset = 0;
        for (let i = 0; this.countdata / cal_limit > i; i++) {
          cal_offset = cal_limit * i;
          let payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: "",
            status_file: "T",
            status_folder: "T",
            limit: cal_limit,
            offset: cal_offset,
            sort_by: this.sort_by,
            direction: this.direction,
          };
          let auth = await gbfGenerate.generateToken();
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder",
              payload,
              {
                headers: { Authorization: auth.code },
                cancelToken: this.sourceselectall.token,
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                for (let i = 0; i < response.data.data.length; i++) {
                  let dataFileAndFolder = {};
                  let typefile = response.data.data[i].name.split(".");
                  let type = response.data.data[i].data_type;
                  dataFileAndFolder["account_reciever"] = "";
                  dataFileAndFolder["account_sender"] = this.dataAccountId;
                  dataFileAndFolder["account_id"] =
                    response.data.data[i].account_id;
                  dataFileAndFolder["countnum"] =
                    response.data.data[i].countnum;
                  dataFileAndFolder["file_createdtm"] =
                    response.data.data[i].cre_dtm;
                  dataFileAndFolder["folder_id"] =
                    response.data.data[i].folder_id;
                  dataFileAndFolder["file_id"] = response.data.data[i].id;
                  dataFileAndFolder["file_lastdtm"] =
                    response.data.data[i].last_dtm;
                  dataFileAndFolder["file_linkshare"] =
                    response.data.data[i].link;
                  dataFileAndFolder["file_name"] = response.data.data[i].name;
                  dataFileAndFolder["file_owner_eng"] =
                    response.data.data[i].name_eng;
                  dataFileAndFolder["file_owner_th"] =
                    response.data.data[i].name_th;
                  dataFileAndFolder["file_permission"] =
                    response.data.data[i].permission_account;
                  dataFileAndFolder["permission_department_setting"] =
                    response.data.data[i].permission_setting;
                  dataFileAndFolder["permission_role_setting"] =
                    response.data.data[i].permission_role_setting;
                  dataFileAndFolder["priority"] =
                    response.data.data[i].priority;
                  dataFileAndFolder["file_size"] = response.data.data[i].size;
                  dataFileAndFolder["file_position"] =
                    response.data.data[i].full_path === null
                      ? "-"
                      : response.data.data[i].full_path;
                  dataFileAndFolder["file_status"] =
                    response.data.data[i].status;
                  dataFileAndFolder["file_status_share"] =
                    response.data.data[i].status_share;
                  dataFileAndFolder["file_status_sharelink"] =
                    response.data.data[i].status_share_link;
                  dataFileAndFolder["system"] = response.data.data[i].system;
                  dataFileAndFolder["type"] = response.data.data[i].data_type;
                  // dataFileAndFolder["file_icon"] = this.seticon_New(
                  //   type,
                  //   typefile[typefile.length - 1]
                  // );
                  dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
                  dataFileAndFolder["file_type"] =
                    type == "folder" ? "folder" : typefile[typefile.length - 1];
                  dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                  dataFileAndFolder["status_lock_folder"] =
                    response.data.data[i]["status_lock_folder"];
                  dataFileAndFolder["status_lock_file"] =
                    response.data.data[i]["status_lock_file"];
                  dataFileAndFolder["date_effect"] =
                    response.data.data[i].header_info["date_effect"];
                  dataFileAndFolder["doc_id"] =
                    response.data.data[i].header_info["doc_id"];
                  dataFileAndFolder["tax_version"] = "00";
                  dataFileAndFolder["trash_dtm"] =
                    response.data.data[i].trash_dtm;
                  dataFileAndFolder["permission_account_v2"] =
                    response.data.data[i].permission_account_v2;
                  dataFileAndFolder["last_update_name_th"] =
                    response.data.data[i].last_update_name.name_th;
                  dataFileAndFolder["last_update_name_en"] =
                    response.data.data[i].last_update_name.name_eng;
                  this.filemultipledelete.push(dataFileAndFolder);
                }
              } else {
                this.countdeleteerror = 1;
              }
            })
            .catch((error) => {
              if (this.countcancel > 0) {
                this.statuscleartrash = "cancelselect";
              } else {
                this.countdeleteerror = 1;
              }
            });
          if (this.countdeleteerror > 0) {
            break;
          } else {
            continue;
          }
        }
        if (this.countcancel > 0) {
          this.statuscleartrash = "cancelselect";
        } else if (this.countdeleteerror > 0) {
          this.opendialogsnackbarcleartrash = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        } else {
           this.backupfileandfolder();
        }
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("trashPage.nofileandfolderintrash"),
        });
      }
    },
    // ฟังก์ชันลบไปยังที่สำรองข้อมูล
    async backupfileandfolder() {
      this.statuscleartrash = "delete";
      let filedata = [];
      this.countdelete = 0;
      this.showdatadelete = [];
      let CancelToken = this.axios.CancelToken;
      this.sourcemultidelete = CancelToken.source();
      for (let i = 0; i < this.filemultipledelete.length; i++) {
        this.countdelete = i + 1;
        // สร้างข้อมูลสำหรับโชว์
        let datadelete = {
          backup_status: {
            status: "",
            message: "",
            value: 0,
          },
          canceltoken: this.sourcemultidelete,
          file_name: this.filemultipledelete[i].file_name,
        };
        this.showdatadelete.push(datadelete);
        let auth = await gbfGenerate.generateToken();
        if (this.filemultipledelete[i].file_type === "folder") {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "folder",
          });
        } else {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "file",
          });
        }
        if (filedata[i].type === "folder") {
          let payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: filedata[i].id,
          };
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_DELETE_MULTIPLES +
                "/api/delete_folder_to_backup",
              payload,
              {
                headers: { Authorization: auth.code },
                cancelToken: this.showdatadelete[i].canceltoken.token,
                onUploadProgress: (e) => {
                  if ((e.loaded / e.total) * 100 >= 95) {
                    this.showdatadelete[i].backup_status.value = 95;
                  } else {
                    this.showdatadelete[i].backup_status.value = parseInt(
                      (e.loaded / e.total) * 100
                    );
                  }
                },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.showdatadelete[i].backup_status.status = "OK";
                this.showdatadelete[i].backup_status.message = "success";
                this.showdatadelete[i].backup_status.value = 100;
              } else {
                // ลบไม่สำเร็จ
                this.showdatadelete[i].backup_status.status =
                  response.data.status;
                this.showdatadelete[i].backup_status.message =
                  response.data.errorMessage;
                this.countdeleteerror = this.countdeleteerror + 1;
              }
            })
            .catch((error) => {
              if (this.countcancel > 0) {
                this.showdatadelete[i].backup_status.status = "ER";
                this.showdatadelete[i].backup_status.message = "Cancel Delete";
                this.statuscleartrash = "canceldelete";
              } else {
                this.showdatadelete[i].backup_status.status = "ER";
                this.showdatadelete[i].backup_status.message = error;
                this.countdeleteerror = this.countdeleteerror + 1;
              }
            });
        } else if (filedata[i].type === "file") {
          let payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            file_id: filedata[i].id,
          };
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_DELETE_MULTIPLES +
                "/api/delete_file_to_backup",
              payload,
              {
                headers: { Authorization: auth.code },
                cancelToken: this.showdatadelete[i].canceltoken.token,
                onUploadProgress: (e) => {
                  if ((e.loaded / e.total) * 100 >= 95) {
                    this.showdatadelete[i].backup_status.value = 95;
                  } else {
                    this.showdatadelete[i].backup_status.value = parseInt(
                      (e.loaded / e.total) * 100
                    );
                  }
                },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.showdatadelete[i].backup_status.status =
                  response.data.status;
                this.showdatadelete[i].backup_status.message = "success";
                this.showdatadelete[i].backup_status.value = 100;
              } else {
                // ลบไม่สำเร็จ
                this.showdatadelete[i].backup_status.status =
                  response.data.status;
                this.showdatadelete[i].backup_status.message =
                  response.data.errorMessage;
                this.countdeleteerror = this.countdeleteerror + 1;
              }
            })
            .catch((error) => {
              // เมื่อกด cancel จะตก catch จึงต้องเช็คว่าตก catch จากการกด cancel หรือเปล่า
              if (this.countcancel > 0) {
                this.showdatadelete[i].backup_status.status = "ER";
                this.showdatadelete[i].backup_status.message = "Cancel Delete";
                this.statuscleartrash = "canceldelete";
              } else {
                this.showdatadelete[i].backup_status.status = "ER";
                this.showdatadelete[i].backup_status.message = error;
                this.countdeleteerror = this.countdeleteerror + 1;
              }
            });
        } else {
          // ประเภทไฟล์ไม่ใช่ทั้งไฟล์หรือโฟลเดอร์
          this.countdeleteerror = this.countdeleteerror + 1;
          this.showdatadelete[i].backup_status.status = "ER";
          this.showdatadelete[i].backup_status.message =
            "File Type not correct";
        }
      }
      if (this.countdeleteerror === 0 && this.countcancel === 0) {
        setTimeout(() => {
          this.opendialogsnackbarcleartrash = false;
          this.fn_loadfileandfolder_child();
          this.loadstorage();
        }, 2500);
      }
    },
    async openMoveFileAndFolder (parameter) {
      this.file = parameter
      this.opendlmovefile = true
    },
    async backupfileandfolder_V2() { // ไม่ได้ใช้งาน
      this.statuscleartrash = "delete";
      let filedata = [];
      this.countdelete = 0;
      this.showdatadelete = [];
      let CancelToken = this.axios.CancelToken;
      this.sourcemultidelete = CancelToken.source();
      for (let i = 0; i < this.filemultipledelete.length; i++) {
        this.countdelete = i + 1;
        // สร้างข้อมูลสำหรับโชว์
        let datadelete = {
          backup_status: {
            status: "",
            message: "",
            value: 0,
          },
          canceltoken: this.sourcemultidelete,
          file_name: this.filemultipledelete[i].file_name,
        };
        this.showdatadelete.push(datadelete);
        let auth = await gbfGenerate.generateToken();
        if (this.filemultipledelete[i].file_type === "folder") {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "folder",
          });
        } else {
          filedata.push({
            id: this.filemultipledelete[i]["file_id"],
            type: "file",
          });
        }
        if(!this.fn_permission_folder(this.filemultipledelete[i].permission_account_v2) ){
          if (filedata[i].type === "folder") {
            let payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              data_id: filedata[i].id,
              data_type: filedata[i].type
            };
            await this.axios
              .post(
                process.env.VUE_APP_SERVICE_DELETE_MULTIPLES + "/api/delete_folder_file_trash_to_backup",
                payload,
                {
                  headers: { Authorization: auth.code },
                  cancelToken: this.showdatadelete[i].canceltoken.token,
                  onUploadProgress: (e) => {
                    if ((e.loaded / e.total) * 100 >= 95) {
                      this.showdatadelete[i].backup_status.value = 95;
                    } else {
                      this.showdatadelete[i].backup_status.value = parseInt(
                        (e.loaded / e.total) * 100
                      );
                    }
                  },
                }
              )
              .then((response) => {
                if (response.data.status === "OK") {
                  this.showdatadelete[i].backup_status.status = "OK";
                  this.showdatadelete[i].backup_status.message = "success";
                  this.showdatadelete[i].backup_status.value = 100;
                } else {
                  // ลบไม่สำเร็จ
                  this.showdatadelete[i].backup_status.status =
                    response.data.status;
                  this.showdatadelete[i].backup_status.message =
                    response.data.errorMessage;
                  this.countdeleteerror = this.countdeleteerror + 1;
                }
              })
              .catch((error) => {
                if (this.countcancel > 0) {
                  this.showdatadelete[i].backup_status.status = "ER";
                  this.showdatadelete[i].backup_status.message = "Cancel Delete";
                  this.statuscleartrash = "canceldelete";
                } else {
                  this.showdatadelete[i].backup_status.status = "ER";
                  this.showdatadelete[i].backup_status.message = error;
                  this.countdeleteerror = this.countdeleteerror + 1;
                }
              });
          } else if (filedata[i].type === "file") {
            let payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              data_id: filedata[i].id+'ss',
              data_type: filedata[i].type
            };
            await this.axios
              .post(
                process.env.VUE_APP_SERVICE_DELETE_MULTIPLES + "/api/delete_folder_file_trash_to_backup",
                payload,
                {
                  headers: { Authorization: auth.code },
                  cancelToken: this.showdatadelete[i].canceltoken.token,
                  onUploadProgress: (e) => {
                    if ((e.loaded / e.total) * 100 >= 95) {
                      this.showdatadelete[i].backup_status.value = 95;
                    } else {
                      this.showdatadelete[i].backup_status.value = parseInt(
                        (e.loaded / e.total) * 100
                      );
                    }
                  },
                }
              )
              .then((response) => {
                if (response.data.status === "OK") {
                  this.showdatadelete[i].backup_status.status =
                    response.data.status;
                  this.showdatadelete[i].backup_status.message = "success";
                  this.showdatadelete[i].backup_status.value = 100;
                } else {
                  // ลบไม่สำเร็จ
                  this.showdatadelete[i].backup_status.status =
                    response.data.status;
                  this.showdatadelete[i].backup_status.message =
                    response.data.errorMessage;
                  this.countdeleteerror = this.countdeleteerror + 1;
                }
              })
              .catch((error) => {
                // เมื่อกด cancel จะตก catch จึงต้องเช็คว่าตก catch จากการกด cancel หรือเปล่า
                if (this.countcancel > 0) {
                  this.showdatadelete[i].backup_status.status = "ER";
                  this.showdatadelete[i].backup_status.message = "Cancel Delete";
                  this.statuscleartrash = "canceldelete";
                } else {
                  this.showdatadelete[i].backup_status.status = "ER";
                  this.showdatadelete[i].backup_status.message = error;
                  this.countdeleteerror = this.countdeleteerror + 1;
                }
              });
          } else {
            // ประเภทไฟล์ไม่ใช่ทั้งไฟล์หรือโฟลเดอร์
            this.countdeleteerror = this.countdeleteerror + 1;
            this.showdatadelete[i].backup_status.status = "ER";
            this.showdatadelete[i].backup_status.message =
              "File Type not correct";
          }
        } else{
            this.countdeleteerror = this.countdeleteerror + 1;
            this.showdatadelete[i].backup_status.status = "ER";
            this.showdatadelete[i].backup_status.message = this.$t('multirecovery.deletepermission')
        }
      }
      if (this.countdeleteerror === 0 && this.countcancel === 0) {
        setTimeout(() => {
          this.opendialogsnackbarcleartrash = false;
          this.fn_loadfileandfolder_child();
          this.loadstorage();
          this.filemultipledelete = []
          this.statusmutipledelete = false
        }, 2500);
      }
    },
    checkSort() {
      // this.loadfolder();
      this.fn_loadfileandfolder_child();
      // this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootfile = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }
    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.trash_dtm.localeCompare(b.trash_dtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.trash_dtm.localeCompare(b.trash_dtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.trash_dtm.localeCompare(a.trash_dtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.trash_dtm.localeCompare(a.trash_dtm);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "owner") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   } else if (this.sortby === "position") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_position.localeCompare(b.file_position);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_position.localeCompare(b.file_position);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_position.localeCompare(a.file_position);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_position.localeCompare(a.file_position);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootfile.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootfile;
    // },
    filteredItems(search) {
      this.search = search;
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute
        );
        // return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
    },
    async recoveryfile(parameter) {
      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            "/api/recovery_file_folder_from_trash",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("delresponse", response);
            if (this.resolutionScreen >= 400) {
              Toast.fire({
                icon: "success",
                title:
                  this.$t("toast.text.restore") +
                  " " +
                  parameter.file_name +
                  this.$t("toast.text.success"),
              });
              this.fn_loadfileandfolder_child();
            } else {
              this.opendialogrestore = true;
            }
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async setPermissionFile(parameter, status) {
      if (
        parameter.permission_account_v2 === "05" ||
        parameter.permission_account_v2 === "06"
      ) {
        let auth = await gbfGenerate.generateToken();
        let api;
        let payload;
        let msgres;
        if (status === "N") {
          //Add Now
          this.file = parameter
          this.file["file_permission_2"] = this.file["permission_account_v2"]
          this.parentfolder = this.account_active["directory_id"]
          this.opendialogmultirestore = true
          this.filemultipledelete = [parameter]
          //////////////////////////////////////

          // this.recoveryfile(parameter); --> then
        } else {
          if (parameter.account_sender === this.dataAccountId) {
            if (parameter.file_type === "folder") {
              payload = {
                account_id: this.dataAccountId,
                folder_id: parameter.file_id,
                folder_name: "",
                status_folder: status,
                move_to_id: "",
                user_id: this.dataUsername,
              };
              api = "/api/v2/update_folder_id";
            } else {
              payload = {
                account_id: this.dataAccountId,
                user_id: this.dataUsername,
                file_id: parameter.file_id,
                filename: "",
                folder_id: "",
                status_file: status,
              };
              api = "/api/v2/update_file_id";
            }
            this.axios
              .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
                headers: { Authorization: auth.code },
              })
              .then((response) => {
                console.log(response);
                if (response.data.status === "OK") {
                  // กู้คืน
                  if (status === "N") {
                    // msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
                    msgres =
                      this.$t("toast.text.restore") +
                      " " +
                      parameter.file_name +
                      this.$t("toast.text.success");
                  } else {
                    // msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
                    msgres =
                      this.$t("toast.text.canclestart") +
                      " " +
                      parameter.file_name +
                      this.$t("toast.text.success");
                  }

                  // Toast.fire({
                  //   icon: "success",
                  //   title: msgres,
                  // });
                  // this.$emit('closecurrent')
                  // this.loadfolder();
                  // this.loadfileandfolder();
                  // this.loadsharefolder();
                  // this.fn_loadfileandfolder_child();
                  if (this.resolutionScreen >= 400) {
                    Toast.fire({
                      icon: "success",
                      title:
                        this.$t("toast.text.restore") +
                        " " +
                        parameter.file_name +
                        this.$t("toast.text.success"),
                    });
                    this.fn_loadfileandfolder_child();
                  } else {
                    this.opendialogrestore = true;
                  }
                } else {
                  Toast.fire({
                    icon: "error",
                    title: response.data.errorMessage,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                Toast.fire({
                  icon: "error",
                  // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
                  title:
                    this.$t("toast.text.cannotstar") +
                    parameter.file_name +
                    this.$t("toast.text.textth"),
                });
              });
          } else {
            let api;
            let payload;
            let msgres;
            if (parameter.file_type === "folder") {
              payload = {
                id: parameter.file_id,
                account_id: this.dataAccountId,
                status_folder: status,
              };
              api = "/api/update_share_statusfolder";
            } else {
              payload = {
                id: parameter.file_id,
                account_id: this.dataAccountId,
                status_file: status,
              };
              api = "/api/update_share_statusfile";
            }
            console.log("payload =>", payload);
            let auth = await gbfGenerate.generateToken();
            await this.axios
              .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
                headers: { Authorization: auth.code },
              })
              .then((response) => {
                console.log(response);
                if (response.data.status === "OK") {
                  if (status === "N") {
                    // msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
                    msgres =
                      this.$t("toast.text.restore") +
                      " " +
                      parameter.file_name +
                      this.$t("toast.text.success");
                  } else {
                    // msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
                    msgres =
                      this.$t("toast.text.canclestart") +
                      " " +
                      parameter.file_name +
                      this.$t("toast.text.success");
                  }
                  Toast.fire({
                    icon: "success",
                    title: msgres,
                  });
                  // this.$emit('closecurrent')
                  // this.loadfolder();
                  // this.loadfileandfolder()
                  // this.loadsharefolder();
                  this.fn_loadfileandfolder_child();
                } else {
                  Toast.fire({
                    icon: "error",
                    title: response.data.errorMessage,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                Toast.fire({
                  icon: "error",
                  // title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
                  title:
                    this.$t("toast.text.cannotstar") +
                    " " +
                    parameter.file_name +
                    this.$t("toast.text.textth"),
                });
              });
          }
        }
      } else {
        Toast.fire({
          icon: "error",
          title: "ไม่มีสิทธิ์กู้คืนไฟล์",
        });
      }
    },
    msgalert(file) {
      if (
        file.permission_account_v2 === "05" ||
        file.permission_account_v2 === "06"
      ) {
        this.datadelete = file;
        this.msgConfirm = true;
      } else {
        Toast.fire({
          icon: "error",
          title: "ไม่มีสิทธิ์ลบไฟล์",
        });
      }
    },
    // ในการลบแบบธรรมดา
    callremove(file) { 
      if (file["file_type"] === "folder") {
        this.deletefolder(file);
      } else {
        this.deletefile(file);
      }
    },
    // ลบไฟล์
    async deletefile(file) {
      this.processdelete = true;
      let msg;
      console.log("fileeeeee", file);

      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        data_id: file["file_id"], // เปลี่ยน key -> จากหัวข้อ "file_id"
        data_type: file.type // Add new
        // type:file.file_type ชนิดของไฟล์
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/delete_folder_file_trash_to_backup",
        payload,
        { headers: { Authorization: auth.code } }
      );
      try {
        console.log(response);
        this.processdelete = false;
        this.msgConfirm = false;
        if (response.data.status === "OK") {
          if (this.resolutionScreen < 400) {
            this.opendeletesuccess = true;
          } else {
            Toast.fire({
              icon: "success",
              title: this.$t("sharefile.deletesuc"),
            });
            this.loadstorage();
            this.fn_loadfileandfolder_child();
          }
          // this.loadfileandfolder()
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.status + ": " + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.processdelete = false;
        this.msgConfirm = false;
        Toast.fire({
          icon: "error",
          title: ex,
        });
        // }
      }
    },
    // ลบโฟลเดอร์
    async deletefolder(file) {
      this.processdelete = true;
      let msg;
      console.log("folder", file);

      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        data_id: file["file_id"], // เปลี่ยน key -> จาก "folder_id"
        data_type: file.type // Add new
        // type:file.file_type,
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DELETE_FOLDER + "/api/delete_folder_file_trash_to_backup",
        payload,
        { headers: { Authorization: auth.code } }
      );
      try {
        console.log(response);
        this.processdelete = false;
        this.msgConfirm = false;
        if (response.data.status === "OK") {
          if (this.resolutionScreen < 400) {
            this.opendeletesuccess = true;
          } else {
            Toast.fire({
              icon: "success",
              title: this.$t("sharefile.deletesuc"),
            });
            this.fn_loadfileandfolder_child();
            // this.loadfileandfolder()
            this.loadstorage();
          }
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.status + ": " + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.processdelete = false;
        this.msgConfirm = false;
        Toast.fire({
          icon: "error",
          title: ex,
        });
      }
      // }
    },

    rightclickfileandfolder(e, file) {
      // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
      if (!this.arrayfileidselect.includes(file.file_id)) {
        this.arrayfileidselect = [file.file_id]
      }
      this.filedata = file;
      console.log(e);
      e.preventDefault();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        console.log(file);
        this.currentfile = file;
        this.showcurrentMenu = true;
      });
    },

    gotodirectory(folderid, type) {
      if (type === "folder") {
        this.$router.replace({ path: "/directory/" + folderid });
        this.fn_loadfileandfolder_child();
        // this.loadfileandfolder()
      }
    },

    show(e) {
      console.log(e);
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },

    onDrop(files) {
      console.log(files);
    },
    
    // โหลด file & folder new
    async loadfileandfolder() {
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      // CLEAR PARAMETER
      // this.newrootfile = [];
      this.fileCount = 0;
      this.count_file = 0;
      this.count_folder = 0;
      this.buff = [];
      this.rootfile = [];

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        // folder_id: this.account_active["type"] === "Business"  ? this.account_active["business_info"]["my_folder_id"] : this.account_active["directory_id"],
        status_file: "T",
        status_folder: "T",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
      .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("Response new API(My file) ", response.data);
          if (response.data.status === "OK") {
            console.log("Response new API(My file) ", response.data);
            // ใช้ของเก่าก่อน
            this.fileCount = response.data.count;
            this.count_file = response.data.count_file;
            this.count_folder = response.data.count_folder;

            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] =
                response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].types;
              // dataFileAndFolder["file_icon"] = this.seticon_New(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["trash_dtm"] = response.data.data[i].trash_dtm;
              dataFileAndFolder["permission_account_v2"] =
                response.data.data[i].permission_account_v2;

              this.rootfile.push(dataFileAndFolder);
            }
            console.log("Newrootfile(My file)", this.rootfile);
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // ตั้ง icon file/folder
    seticonNew(type, raw_parameter) {
      console.log(type);

      let dataicon;
      let parameter;
      if (typeof raw_parameter === "string") {
        parameter = raw_parameter.toLowerCase();
      } else {
        parameter = raw_parameter;
      }
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (type == "folder") {
        dataicon = ["folder", "#FDD361"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif" ||
          parameter === "bmp"
        ) {
          dataicon = [
            "mdi-file-image",
            "blue-grey",
            // "#F6AD01",
          ];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else {
          // dataicon = ["mdi-file-question-outline", "black"];
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },
    // ตั้ง icon file/folder
    seticon_New(type, parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      console.log(parameter);
      if (type == "folder") {
        // dataicon = ["folder",
        // // "blue"
        //  "#FDD361"
        // ];
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          // dataicon = ["mdi-file-excel", "green"];
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          // dataicon = ["mdi-file-powerpoint", "orange"];
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          // dataicon = ["mdi-file-word", "primary"];
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          // dataicon = ["mdi-file-pdf", "red"];
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif" ||
          parameter === "bmp"
        ) {
          // dataicon = [
          //   "mdi-file-image",
          //   "blue-grey",
          //   // "#F6AD01",
          // ];
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }

      return dataicon;
    },
    async loadfolder($state) {
      this.folder = [];
      this.rootfile = [];
      this.buff = [];
      var payload = {
        user_id: this.dataUsername,
        folder_id: "",
        status_folder: "T",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/v2/search_folder_byid",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            // console.log("folderbyid",response.data.folder.length);
            console.log("foldershare", response);

            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_size"] = "-";
              datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"];
              datafolder["file_status"] =
                response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] =
                response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              // datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon("folder");
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.folder.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.countfolder = response.data.folder.length;
            this.loadfiles("", "T");
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    async loadfiles(folder, status) {
      this.file = [];
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder,
        status_file: status,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            // console.log("file",response.data.data.length);

            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              // datafolder["file_icon"] = this.seticon_(
              //   typefile[typefile.length - 1]
              // );
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon(typefile[typefile.length - 1]);
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.file.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loaddataprogress = false;
            this.countfile = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    loadsharefolder($state) {
      this.folder = [];
      this.rootfile = [];
      this.buff = [];
      console.log(this.dataAccountActive["type"]);
      var payload = {
        account_id: this.dataAccountId,
        status: "T",
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/get_sharefolder",
          payload
        )
        .then((response) => {
          // console.log("apipipi",response);
          if (response.data.status === "OK") {
            console.log("555555", response);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] =
                response.data.data[i]["account_id_reciever"];
              datafolder["account_sender"] =
                response.data.data[i]["account_id_sender"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_size"] = "-";
              datafolder["file_status"] =
                response.data.data[i]["status_folder"];
              datafolder["file_name"] = response.data.data[i]["folder_name"];
              datafolder["file_owner_eng"] =
                response.data.data[i]["sender_name"];
              datafolder["file_owner_th"] =
                response.data.data[i]["sender_name"];
              datafolder["file_linkshare"] = "";
              datafolder["file_date"] = response.data.data[i]["share_dtm"];
              datafolder["file_lastdtm"] = "";
              datafolder["file_type"] = "folder";
              datafolder["file_status_share"] = "Y";
              datafolder["file_permission"] = response.data.data[i]["status"];
              datafolder["file_status_sharelink"] = "";
              datafolder["file_department"] = "";
              // datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon("folder");
              datafolder["trash_dtm"] = response.data.data[i].trash_dtm;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.folder.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootsharefile));
            }
            this.loaddataprogress = false;
            this.loadsharefile("", "T");
          } else {
            this.loadsharefile("", "T");
            this.countfolder = 0;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },

    loadsharefile() {
      this.file = [];
      var payload = {
        account_id: this.dataAccountId,
        status: "T",
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SHARE_FILE + "/api/get_sharefile",
          payload
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("loadfiles", response);
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] =
                response.data.data[i]["account_id_reciever"];
              datafolder["account_sender"] =
                response.data.data[i]["account_id_sender"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] =
                response.data.data[i]["sender_name"];
              datafolder["file_owner_th"] =
                response.data.data[i]["sender_name"];
              datafolder["file_linkshare"] = "";
              datafolder["file_status_share"] = "Y";
              datafolder["file_status_sharelink"] = "";
              datafolder["file_permission"] = response.data.data[i]["status"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["file_date"] = response.data.data[i]["share_dtm"];
              datafolder["file_lastdtm"] = "";
              // datafolder["file_icon"] = this.seticon_(
              //   typefile[typefile.length - 1]
              // );
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon(typefile[typefile.length - 1]);
              datafolder["trash_dtm"] = response.data.data[i].trash_dtm;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.file.push(datafolder);
              // console.log("status", datafolder["file_permission"]);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootsharefile));
            }
            this.loaddataprogress = false;
            this.countfile = response.data.data.length;
          } else {
            this.loaddataprogress = false;
            this.countfile = 0;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    seticon(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    seticon_(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },

    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },

    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },

    async fn_loadfileandfolder() {
      console.log("this.account_active", this.account_active);
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      console.log(cal_offset);
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }
      this.disablescrolling = true;
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        // folder_id: this.account_active["type"] === "Business"  ? this.account_active["business_info"]["my_folder_id"] : this.account_active["directory_id"],
        status_file: "T",
        status_folder: "T",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_trash", // then "/api/select_files_and_folder"
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then(async (response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] =
                response.data.data[i].permission_role_setting;
              dataFileAndFolder["permission_role_setting"] =
                response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_position"] =
                response.data.data[i].full_path === null
                  ? "-"
                  : response.data.data[i].full_path;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              // dataFileAndFolder["file_icon"] = this.seticon_New(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] =
                response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] =
                response.data.data[i]["status_lock_file"];
              dataFileAndFolder["date_effect"] =
                response.data.data[i].header_info["date_effect"];
              dataFileAndFolder["doc_id"] =
                response.data.data[i].header_info["doc_id"];
              dataFileAndFolder["tax_version"] = "00";
              dataFileAndFolder["trash_dtm"] = response.data.data[i].trash_dtm;
              dataFileAndFolder["full_path"] = response.data.data[i].full_path // Add new
              dataFileAndFolder["full_path_id"] = response.data.data[i].full_path_id // Add new
              dataFileAndFolder["permission_account_v2"] =
                response.data.data[i].permission_account_v2;
              dataFileAndFolder["last_update_name_th"] =
                response.data.data[i].last_update_name.name_th;
              dataFileAndFolder["last_update_name_en"] =
                response.data.data[i].last_update_name.name_eng;
              this.rootfile.push(dataFileAndFolder);
            }
            console.log("this.rootfile", this.rootfile);
            this.countdata = response.data.count;
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.count_file = response.data.count_file;
            this.count_folder = response.data.count_folder;
            
            this.recent_offset = { // ไว้ใช้ในการ loading ของ diloag detail delete รูปแบบ clear trash
              limit: 20,
              offset: cal_offset,
              all_file: this.count_file,
              all_folder: this.count_folder,
              sort_by: this.sort_by,
              direction: this.direction,
            }
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;

            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].file_owner_th === "" ||
                this.rootfile[y].file_owner_eng === ""
              ) {
                await this.get_name_owner(this.rootfile[y]);
              }
            }

            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].last_update_name_th === "" ||
                this.rootfile[y].last_update_name_en === ""
              ) {
                await this.get_last_update_name(this.rootfile[y]);
              }
            }
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile) {
      console.log("listrootfile", listrootfile);
      let payload = {
        data_id: listrootfile.file_id,
        data_type: listrootfile.file_type === "folder" ? "folder" : "file",
        type_search: "creator",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      console.log("auth.code", auth.code);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/v1/get/data/creator_name_trash", // then "/api/v1/get/data/creator_name",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // listrootfile.last_update_name_eng = response.data.name.name_eng;
            // listrootfile.last_update_name_th = response.data.name.name_th;
            listrootfile.file_owner_eng = response.data.name.name_eng;
            listrootfile.file_owner_th = response.data.name.name_th;
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    async get_last_update_name(listrootfile) {
      console.log("listrootfile", listrootfile);
      let payload = {
        data_id: listrootfile.file_id,
        data_type: listrootfile.file_type === "folder" ? "folder" : "file",
        type_search: "last_update",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      console.log("auth.code", auth.code);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/v1/get/data/creator_name_trash", // then "/api/v1/get/data/creator_name"
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.last_update_name_eng = response.data.name.name_eng;
            listrootfile.last_update_name_th = response.data.name.name_th;
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async fn_loadfileandfolder_child() {
      // console.log("this.account_active", this.account_active);
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        // folder_id: this.account_active["type"] === "Business"  ? this.account_active["business_info"]["my_folder_id"] : this.account_active["directory_id"] ,
        status_file: "T",
        status_folder: "T",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_trash",  // then "/api/select_files_and_folder"
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then(async (response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] =
                response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_position"] =
                response.data.data[i].full_path === null
                  ? "-"
                  : response.data.data[i].full_path;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              // dataFileAndFolder["file_icon"] = this.seticon_New(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] =
                response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] =
                response.data.data[i]["status_lock_file"];
              dataFileAndFolder["date_effect"] =
                response.data.data[i].header_info["date_effect"];
              dataFileAndFolder["doc_id"] =
                response.data.data[i].header_info["doc_id"];
              dataFileAndFolder["tax_version"] = "00";
              dataFileAndFolder["trash_dtm"] = response.data.data[i].trash_dtm;
              dataFileAndFolder["full_path"] = response.data.data[i].full_path //Add Mai
              dataFileAndFolder["full_path_id"] = response.data.data[i].full_path_id //Add Mai
              dataFileAndFolder["permission_account_v2"] =
                response.data.data[i].permission_account_v2;
              dataFileAndFolder["last_update_name_th"] =
                response.data.data[i].last_update_name.name_th;
              dataFileAndFolder["last_update_name_en"] =
                response.data.data[i].last_update_name.name_eng;
              this.rootfile.push(dataFileAndFolder);
            }
            console.log("this.rootfile", this.rootfile);
            this.countdata = response.data.count;
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.count_file = response.data.count_file;
            this.count_folder = response.data.count_folder;
            this.recent_offset = { // ไว้ใช้ในการ loading ของ diloag detail delete รูปแบบ clear trash
              limit: 20,
              offset: cal_offset,
              all_file: this.count_file,
              all_folder: this.count_folder,
              sort_by: this.sort_by,
              direction: this.direction,
            }
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;

            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].file_owner_th === "" ||
                this.rootfile[y].file_owner_eng === ""
              ) {
                await this.get_name_owner(this.rootfile[y]);
              }
            }

            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].last_update_name_th === "" ||
                this.rootfile[y].last_update_name_en === ""
              ) {
                await this.get_last_update_name(this.rootfile[y]);
              }
            }
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }
    },
    setSelectitemtable(file) {
      console.log('file',file)
      if (file.file_id) {
        this.arrayfileidselect = [];
        if (this.statusmutipledelete === false) {
          this.arrayfileidselect = [file.file_id]
        }
      }
    },
  },
  mounted() {
    // var self = this;
    // self.loadfolder();
    //this.loadfileandfolder();
    // self.loadsharefolder();
    //  this.fn_loadfileandfolder();
    this.disablescrolling = false;
    this.loaddataprogress = true;
  },
  created() {},
};
</script>

<style>
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_trash {
  height: calc(101vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}
</style>
